var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!this.$store.getters.is_authenticated)?_c('v-container',[_vm._v(_vm._s(_vm.$t("system.login_required")))]):_c('v-container',{attrs:{"fluid":""}},[_c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"id":"rma_form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card',{attrs:{"elevation":"24"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('rma.title'))+" ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('rma.partner'),"required":"","disabled":""},model:{value:(_vm.partner),callback:function ($$v) {_vm.partner=$$v},expression:"partner"}}),_c('validation-provider',{attrs:{"name":"product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"loading":_vm.product_is_loading,"items":_vm.product_items,"search-input":_vm.product_search,"error-messages":errors,"label":_vm.$t('rma.product'),"item-text":"code","item-value":"code","return-object":"","hide-no-data":"","clearable":""},on:{"update:searchInput":function($event){_vm.product_search=$event},"update:search-input":function($event){_vm.product_search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.code)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.description_short)}})],1)]}}],null,true),model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":_vm.$t('rma.serial_number') + ' ' + _vm.$t('rma.serial_number_disclaimer')},model:{value:(_vm.serial),callback:function ($$v) {_vm.serial=$$v},expression:"serial"}}),_c('v-subheader',[_c('b',[_vm._v(_vm._s(_vm.$t('rma.invoice_disclaimer')))])]),_c('validation-provider',{attrs:{"name":"invoice_id","rules":{ custom_invoice: _vm.invoice_date?false:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('rma.invoice_id')},model:{value:(_vm.invoice_id),callback:function ($$v) {_vm.invoice_id=$$v},expression:"invoice_id"}})]}}],null,true)}),[_c('v-menu',{ref:"invoice_date_menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px","error-messages":_vm.errors},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('rma.invoice_date'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.invoice_date),callback:function ($$v) {_vm.invoice_date=$$v},expression:"invoice_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.invoice_date_menu),callback:function ($$v) {_vm.invoice_date_menu=$$v},expression:"invoice_date_menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"elevation":"24","max":new Date().toISOString().substr(0, 10),"min":"1950-01-01","locale":"$i18n.locale","first-day-of-week":1},on:{"change":_vm.invoice_date_save},model:{value:(_vm.invoice_date),callback:function ($$v) {_vm.invoice_date=$$v},expression:"invoice_date"}})],1)],_c('validation-provider',{attrs:{"name":"installation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('rma.installation')},model:{value:(_vm.installation),callback:function ($$v) {_vm.installation=$$v},expression:"installation"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"fault","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":_vm.$t('rma.fault_description')},model:{value:(_vm.fault),callback:function ($$v) {_vm.fault=$$v},expression:"fault"}})]}}],null,true)}),_c('v-file-input',{attrs:{"show-size":"","dense":"","small-chips":"","truncate-length":"30","accept":".zip,.ZIP","label":_vm.$t('rma.attachments')},model:{value:(_vm.attachments),callback:function ($$v) {_vm.attachments=$$v},expression:"attachments"}}),_c('v-subheader',[_vm._v(_vm._s(_vm.$t('rma.attachments_disclaimer')))]),_c('v-btn',{attrs:{"block":"","disabled":invalid,"type":"submit"}},[_vm._v(_vm._s(_vm.$t('rma.btn_send')))])],2)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }