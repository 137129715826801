<template>
<v-container v-if="!this.$store.getters.is_authenticated">{{ $t("system.login_required") }}</v-container>
<v-container fluid v-else>
  <v-container>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form id="rma_form" @submit.prevent="submit">
      <v-card elevation="24">
        <v-card-title>
          {{$t('rma.title')}}
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="partner" :label="$t('rma.partner')" required disabled></v-text-field>

          <validation-provider v-slot="{ errors }" name="product" rules="required">
            <!-- <v-text-field v-model="product" :error-messages="errors" :label="$t('rma_new.product')"></v-text-field> -->
            <v-autocomplete v-model="product" :loading="product_is_loading" :items="product_items" :search-input.sync="product_search" :error-messages="errors" :label="$t('rma.product')" item-text="code" item-value="code" return-object hide-no-data clearable>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.code"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.description_short"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
          </validation-provider>
          
          
          <v-text-field v-model="serial" :label="$t('rma.serial_number') + ' ' + $t('rma.serial_number_disclaimer')" ></v-text-field>
         
          
          <v-subheader><b>{{$t('rma.invoice_disclaimer')}}</b></v-subheader>

          <validation-provider v-slot="{ errors }" name="invoice_id" :rules="{ custom_invoice: invoice_date?false:true}">
            <v-text-field v-model="invoice_id" :error-messages="errors" :label="$t('rma.invoice_id')"></v-text-field>
          </validation-provider>
          <template>
            <v-menu ref="invoice_date_menu" v-model="invoice_date_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px" :error-messages="errors">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="invoice_date" :label="$t('rma.invoice_date')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker ref="picker" elevation="24" v-model="invoice_date" :max="new Date().toISOString().substr(0, 10)" min="1950-01-01" locale="$i18n.locale" :first-day-of-week="1"  @change="invoice_date_save"></v-date-picker>
              <!-- color header-color -->
            </v-menu>
          </template>
          <validation-provider v-slot="{ errors }" name="installation" rules="required">
            <v-text-field v-model="installation" :error-messages="errors" :label="$t('rma.installation')"></v-text-field>
          </validation-provider>

          <validation-provider v-slot="{ errors }" name="fault" rules="required">
            <v-textarea v-model="fault" :error-messages="errors" :label="$t('rma.fault_description')"></v-textarea>
          </validation-provider>
          
          <v-file-input v-model="attachments" show-size dense small-chips truncate-length="30" accept=".zip,.ZIP" :label="$t('rma.attachments')"></v-file-input>
          <v-subheader>{{ $t('rma.attachments_disclaimer') }}</v-subheader>
          <v-btn block :disabled="invalid" type="submit">{{ $t('rma.btn_send') }}</v-btn>
        </v-card-text>
      </v-card>
      <!-- vseobecne udaje o produkte, popis zavady, prilohy -->
    </form>
  </validation-observer>
  </v-container>
</v-container>
</template>

<script>
import { i18n } from "@/i18n/i18n"
import ApiService from "@/services/api_service"

import {
  ValidationObserver,
  ValidationProvider,
  configure,
  extend,
  setInteractionMode
} from "vee-validate"

import {
  alpha,
  confirmed,
  digits,
  email,
  regex,
  required
} from "vee-validate/dist/rules"

setInteractionMode("eager");

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values)
  },
})

extend("alpha", alpha);
extend("digits", digits);
extend("email", email);
extend("custom_gdpr", { ...required });
extend("custom_ico", { 
  validate(value, args) {
    return value.ico.length == args.length
  },
  params: ["length"]
})
extend("custom_password", { ...confirmed });
extend("custom_telephone", { ...regex });
extend("custom_invoice", { ...required });
extend("required", required);

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    current_user_id: -1,
    partner: "",
    product: null,
    product_is_loading: false,
    product_items: [],
    product_search: null,
    product_details: "",
    serial: "",
    fault: "",
    installation: "",
    invoice_id: "",
    invoice_date: null,
    invoice_date_menu: false,
    attachments: null,
    errors: null
  }),
  watch: {
    invoice_date_menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    product(val) {
      if(val != null) {
        this.product_details = val.code + ' - ' + val.description_short
      }
      else {
        this.product_details = ""
      }
    },
    product_search(val) {
      val && val !== this.product && val.length > 1 && this.querySelections(val)
    }
  },
  methods: {
    invoice_date_save(date) {
      this.$refs.invoice_date_menu.save(date)
    },
    querySelections(val) {
      ApiService.get(`/search/pl/${val}`)
        .then(response => {
          this.product_items = response.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.product_is_loading = false))
    },
    submit() {
      this.$refs.observer.validate()
      if(this.errors == null) {
        if(this.attachments !== null) {
          var formData = new FormData()
          formData.append('ID_user', this.current_user_id)
          formData.append('attachments', this.attachments)
          formData.append('product', JSON.stringify(this.product))
          formData.append('serial', this.serial)
          formData.append('fault', this.fault)
          formData.append('installation', this.installation)
          formData.append('invoice_id', this.invoice_id)
          formData.append('invoice_date', this.invoice_date)
          ApiService.post_attachments('/rma/new', formData)
          .then(response => {
            console.log(response);
            this.$router.push({ path: '/rmaOverview' })

          })
          .catch(() => {
          
          })
        }
        else {
          ApiService.post('/rma/new', {
            ID_user: this.current_user_id,
            product: this.product,
            serial: this.serial,
            fault: this.fault,
            installation: this.installation,
            invoice_id: this.invoice_id,
            invoice_date: this.invoice_date,
          })
          .then(response => {
            console.log(response)
            this.$router.push({ path: '/rmaOverview' })
          })
          .catch(() => {
          })
        } 
      }
    }
  },
  mounted() {
    this.current_user_id = this.$store.getters.get_user_id
    ApiService.get(`/auth/info/` + this.current_user_id).then((response) => {
			this.partner = response.data.name + ' ' + response.data.surname + ', ' + response.data.sd_company + ', ' + response.data.sd_ico
		}).catch(() => {})
		this.$refs.observer.validate()
  }
};
</script>

<style scoped>
</style>
